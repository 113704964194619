<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                      <b-row>
                        <b-col lg="12" sm="12">
                          <template>
                              <div style="font-size:12px; background-color: #337982; margin:16px">
                                  <h5 class="text-white text-center"> {{ $t('dae_subsidy.farmer_information') }}</h5>
                              </div>
                           </template>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="division_id"
                              slot-scope="{ valid, errors }">
                              <template v-slot:label>
                              {{ $t('org_pro_division.division') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain
                              v-model="BillInfo.division_id"
                              :options="divisionList"
                              :disabled="1"
                              id="division_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="district_id"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('org_pro_district.district') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="BillInfo.district_id"
                                :disabled="1"
                                :options="districtList"
                                id="district_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Upazilla" vid="upazilla_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="upazilla_id"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('org_pro_upazilla.upazilla') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="BillInfo.upazilla_id"
                                :disabled="1"
                                :options="upazilaList"
                                id="upazilla_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Circular Type" vid="circular_type" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="circular_type"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('dae_config.circular_type') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="BillInfo.circular_type"
                                  :options="subsidyCircularTypeList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  id="fiscal_year_id"
                                  >
                                  <template v-slot:first>
                                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                              </b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Circular" vid="circular_id" rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="circular_id"
                              slot-scope="{ valid, errors }">
                              <template v-slot:label>
                              {{ $t('dae_subsidy.circular') }} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                              plain
                              v-model="BillInfo.circular_id"
                              :options="circularList"
                              id="circular_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Farmer" vid="application_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="application_id"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('common_config.farmer_name') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="BillInfo.application_id"
                                :options="applications"
                                id="application_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Mobile No." vid="mobile_no">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="mobile_no"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('common_config.mobile_no') }}
                                </template>
                                <b-form-input
                                type="text"
                                id="pay_grade"
                                v-model="BillInfo.mobile_no"
                                :state="errors[0] ? false : (valid ? true : null)"
                                readonly
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="NID No." vid="nid">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="nid"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('dae_grant_allocation_distribution.nid') }}
                                </template>
                                <b-form-input
                                type="text"
                                id="pay_grade"
                                v-model="BillInfo.nid"
                                :state="errors[0] ? false : (valid ? true : null)"
                                readonly
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="NID No." vid="rate_type">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="rate_type"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('dae_subsidy.landType') }}
                                </template>
                                <b-form-select
                                  plain
                                  v-model="BillInfo.rate_type"
                                  :options="landTypeList"
                                  id="land_type"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="NID No." vid="nid">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="nid"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('dae_config.subsidy_rate') }}
                                </template>
                                <b-form-input
                                type="text"
                                id="pay_grade"
                                v-model="BillInfo.subsidy_rate"
                                :state="errors[0] ? false : (valid ? true : null)"
                                readonly
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="12" sm="12" md="12" xl="12">
                          <template>
                              <div style="font-size:12px; background-color: #337982; margin:16px">
                                  <h5 class="text-white text-center"> {{ $t('dae_subsidy.instrument_info') }}</h5>
                              </div>
                           </template>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Instrument Name" vid="instrument_id" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="instrument_id"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('dae_config.instrument_name') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="BillInfo.instrument_id"
                                :options="instrumentList"
                                id="instrument_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                disabled
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Brand_name" vid="brand_name">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="brand_name">
                                <template v-slot:label>
                                {{ $t('dae_subsidy.brand_name') }}
                                </template>
                                <b-form-input
                                plain
                                v-model="BillInfo.brand_name"
                                id="model"
                                readonly
                                >
                                </b-form-input>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="" vid="model">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="model">
                                <template v-slot:label>
                                {{ $t('dae_subsidy.model') }}
                                </template>
                                <b-form-input
                                plain
                                v-model="BillInfo.model"
                                id="model"
                                readonly
                                >
                                </b-form-input>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="" vid="supplier_name">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="supplier_name">
                                <template v-slot:label>
                                {{ $t('dae_subsidy.supplier_name') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                plain
                                v-model="BillInfo.supplier_name"
                                id="model"
                                readonly
                                >
                                </b-form-input>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="12" sm="12" md="12" xl="12">
                          <template>
                              <div style="font-size:12px; background-color: #337982; margin:16px">
                                  <h5 class="text-white text-center"> {{ $t('dae_subsidy.bill_info') }}</h5>
                              </div>
                           </template>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Actual Price" vid="actual_price" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="actual_price"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('dae_subsidy.actual_price') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                type="text"
                                id="pay_grade"
                                v-model="BillInfo.actual_price"
                                @keyup="remainingCalculation()"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Subsidized Price" vid="subsidized_price" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="subsidized_price"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('dae_subsidy.subsidized_price') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                type="text"
                                id="pay_grade"
                                v-model="BillInfo.subsidized_price"
                                @keyup="remainingCalculation()"
                                :state="errors[0] ? false : (valid ? true : null)"
                                readonly
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                          <ValidationProvider name="Farmer Paid" vid="farmer_paid" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="farmer_paid"
                                slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{ $t('dae_subsidy.farmer_paid') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                type="text"
                                id="pay_grade"
                                v-model="BillInfo.farmer_paid"
                                @keyup="remainingCalculation()"
                                :state="errors[0] ? false : (valid ? true : null)"
                                readonly
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { BillPrepareStore, BillPrepareUpdate, approveFarmerUpazila } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getBillInfoData()
      this.BillInfo = tmp
    }

    this.BillInfo.division_id = this.authUser.office_detail.division_id
    this.BillInfo.district_id = this.authUser.office_detail.district_id
    this.BillInfo.upazilla_id = this.authUser.office_detail.upazilla_id
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      loading: false,
      BillInfo: {
        instrument_id: 0,
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        circular_id: 0,
        subsidized_price: 0,
        actual_price: 0,
        remaining: 0,
        farmer_paid: 0,
        application_id: 0,
        land_type: '',
        subsidy_rate: 0,
        supplier_name: '',
        brand_name: '',
        circular_type: 0
      },
      subsidyList: [],
      districtList: [],
      upazilaList: [],
      applications: []
    }
  },
  computed: {
    saveBtnName: function () {
      return this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save')
    },
    authUser () {
      return this.$store.state.Auth.authUser
    },
    subsidyCircularTypeList: function () {
      return this.$store.state.incentiveGrant.commonObj.subsidyCircularTypeList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    landTypeList: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Haor' : 'হাওর' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Non-haor' : 'সমতল' }
      ]
    return list
    },
    circularList: function () {
      const dataList = this.$store.state.incentiveGrant.commonObj.circularList.filter(item => item.status === 1 && item.type === this.BillInfo.circular_type)
        return dataList.map(item => {
            if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn, fiscal_year_id: item.fiscal_year_id }
            } else {
              return { value: item.value, text: item.text_en, fiscal_year_id: item.fiscal_year_id }
            }
        })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    subsidyRateList: function () {
      return this.$store.state.incentiveGrant.commonObj.subsidyRateList
    },
    instrumentList: function () {
      const instrumentList = this.$store.state.incentiveGrant.commonObj.instrumentList.filter(item => item.status === 1)
      return instrumentList.map(obj => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
      })
    }
  },
  watch: {
    'BillInfo.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'BillInfo.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'BillInfo.circular_id': function (newVal, oldVal) {
      this.getApplication(newVal)
    },
    'BillInfo.application_id': function (newVal, oldVal) {
      this.getFermarData(newVal)
    }
  },
  methods: {
    async getApplication () {
      this.loading = true
      await RestApi.getData(incentiveGrantServiceBaseUrl, `${approveFarmerUpazila}/${this.BillInfo.upazilla_id}`).then(response => {
          if (response.success) {
              const data = response.data
              this.applications = data.filter(item => item.circular_id === this.BillInfo.circular_id).map(item => {
                if (item.circular_id === this.BillInfo.circular_id) {
                  return Object.assign({ value: item.id, text: this.$i18n.locale === 'bn' ? item.farmer.name_bn : item.farmer.name, far_id: item.farmer.id, nid_no: item.farmer.nid_no, mobile: item.farmer.mobile_no, instrument_id: item.instrument_id, type: item.land_type, circular_id: item.circular_id, brand_name_en: item.brand_name, brands_name_bn: item.brand_name_bn, model_name: item.model, suppliers_id: item.supplier_id })
                }
              })
          } else {
              this.applications = []
          }
      })
      this.loading = false
    },
    getFermarData (applicationId) {
      const farmerdata = this.applications.find(item => {
        if (typeof item !== 'undefined' && item.value === parseInt(applicationId)) {
          return item
        }
      })
      this.BillInfo.nid = farmerdata.nid_no
      this.BillInfo.mobile_no = farmerdata.mobile
      this.BillInfo.circular_id = farmerdata.circular_id
      this.BillInfo.instrument_id = farmerdata.instrument_id
      this.BillInfo.land_type = farmerdata.type
      this.BillInfo.rate_type = farmerdata.type
      this.BillInfo.brand_name = this.$i18n.locale === 'en' ? farmerdata.brand_name_en : farmerdata.brands_name_bn
      this.BillInfo.model = farmerdata.model_name
      const supplier = this.$store.state.incentiveGrant.commonObj.supplierList.find(item => item.value === farmerdata.suppliers_id)
      this.BillInfo.supplier_name = this.$i18n.locale === 'en' ? supplier.text_en : supplier.text_bn
      const circularData = this.circularList.find(item => item.value === farmerdata.circular_id)
      const subsidyRateData = this.subsidyRateList.find(item => item.fiscal_year_id === circularData.fiscal_year_id && item.rate_type === this.BillInfo.land_type)
      this.BillInfo.subsidy_rate = subsidyRateData?.percentage
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

      if (divisionId) {
          return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

      if (districtId) {
          return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getBillInfoData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.id) {
          result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${BillPrepareUpdate}/${this.id}`, this.BillInfo)
      } else {
          result = await RestApi.postData(incentiveGrantServiceBaseUrl, BillPrepareStore, this.BillInfo)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      this.loading = false
      if (result.success) {
          this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })

          this.$bvModal.hide('modal-4')
      } else {
          this.$refs.form.setErrors(result.errors)
      }
    },
    remainingCalculation () {
      const circularData = this.circularList.find(item => item.value === this.BillInfo.circular_id)
      const subsidyRateData = this.subsidyRateList.find(item => item.fiscal_year_id === circularData.fiscal_year_id && item.rate_type === this.BillInfo.land_type)
      const percentageAmount = parseFloat(subsidyRateData.percentage / 100)
      this.BillInfo.subsidized_price = percentageAmount * parseFloat(this.BillInfo.actual_price)
      this.BillInfo.farmer_paid = parseFloat(this.BillInfo.actual_price - this.BillInfo.subsidized_price)
    }
  }
}
</script>
