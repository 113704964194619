
<template>
    <b-container fluid>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('dae_subsidy.bill_prepare') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col xs="12" sm="12" md="6" lg="6" xl="4">
                  <b-form-group
                  class="row"
                  label-cols-sm="3"
                  :label="$t('org_pro_division.division')"
                  label-for="division_id"
                  >
                  <b-form-select
                      plain
                      v-model="search.division_id"
                      :options="divisionList"
                      id="division_id"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="4">
                  <b-form-group
                  class="row"
                  label-cols-sm="3"
                  label-cols-md="3"
                  :label="$t('org_pro_district.district')"
                  label-for="district_id"
                  >
                  <b-form-select
                      plain
                      v-model="search.district_id"
                      :options="districtList"
                      id="district_id"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="4">
                  <b-form-group
                  class="row"
                  label-cols-sm="3"
                  :label="$t('org_pro_upazilla.upazilla')"
                  label-for="upazilla_id"
                  >
                  <b-form-select
                      plain
                      v-model="search.upazilla_id"
                      :options="upazilaList"
                      id="upazilla_id"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  </b-form-group>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="4">
                <b-form-group
                  class="row"
                  label-cols-sm="3"
                  :label="$t('dae_config.instrument_name')"
                  label-for="instrument_id"
                >
                    <b-form-select
                      plain
                      v-model="search.instrument_id"
                      :options="instrumentList"
                      id="instrument_id"
                    >
                      <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
              <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row>
          <b-col md="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('dae_subsidy.bill_prepare') }} {{ $t('globalTrans.list') }}</h4>
              </template>
              <template v-slot:headerAction>
                <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
                  <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                </b-button>
              </template>
              <template v-slot:body>
                  <b-overlay :show="loadingState">
                      <b-row>
                          <b-col md="12" class="table-responsive">
                              <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                <template v-slot:cell(index)="data">
                                    {{ $n(data.index + pagination.slOffset) }}
                                </template>
                                <template v-slot:cell(mobile_no)="data">
                                  {{ ($i18n.locale === 'en' ? '0' : '০') }}{{ $n(data.item.mobile_no, { useGrouping: false }) }}
                                </template>
                                <template v-slot:cell(nid)="data">
                                  {{ $n(data.item.nid, { useGrouping: false }) }}
                                </template>
                                <template v-slot:cell(action)="data">
                                  <!-- <b-button class="mr-1" title="View" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button> -->
                                  <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 size="sm" @click="details(data.item)">
                                    <i class="fas fa-eye"></i>
                                  </a>
                                </template>
                              </b-table>
                              <b-pagination
                                align="center"
                                v-model="pagination.currentPage"
                                :per-page="pagination.perPage"
                                :total-rows="pagination.totalRows"
                                @input="searchData"
                              />
                          </b-col>
                      </b-row>
                  </b-overlay>
              </template>
            </iq-card>
          </b-col>
        </b-row>
        <b-modal id="modal-5" size="lg" :title="$t('dae_subsidy.bill_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="item" :key="dtlsItemId"></Details>
        </b-modal>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Form :id="itemId" :key="itemId"/>
        </b-modal>
        <b-modal id="modal-1" size="lg" :title="$t('budget.forward')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Forward :id="itemId" :key="itemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import Details from './Details'
import Forward from './Forward'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { BillPrepareList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Details, Forward
  },
  data () {
    return {
      search: {
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        instrument_id: 0
      },
      districtList: [],
      upazilaList: [],
      item: '',
      itemId: 0,
      dtlsItemId: ''
    }
  },
  computed: {
    authUser () {
      return this.$store.state.Auth.authUser
    },
    subsidyRateList: function () {
      return this.$store.state.incentiveGrant.commonObj.subsidyRateList
    },
    landTypeList: function () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Haor' : 'হাওর' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Non-haor' : 'সমতল' }
      ]
    },
    instrumentList: function () {
      const instrumentList = this.$store.state.incentiveGrant.commonObj.instrumentList.filter(item => item.status === 1)
      return instrumentList.map(obj => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
      })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    formTitle () {
      return (this.itemId === 0) ? this.$t('dae_subsidy.bill_prepare') + ' ' + this.$t('globalTrans.entry') : this.$t('dae_subsidy.bill_prepare') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
        { label: this.$t('common_config.farmer_name'), class: 'text-center' },
        { label: this.$t('common_config.mobile_no'), class: 'text-center' },
        { label: this.$t('dae_grant_allocation_distribution.nid'), class: 'text-center' },
        { label: this.$t('org_pro_district.district'), class: 'text-center' },
        { label: this.$t('org_pro_upazilla.upazilla'), class: 'text-center' },
        { label: this.$t('dae_config.instrument_name'), class: 'text-center' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'farmer_name_bn' },
          { key: 'mobile_no' },
          { key: 'nid' },
          { key: 'district_name_bn' },
          { key: 'upazilla_name_bn' },
          { key: 'instrument_name_bn' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'farmer_name' },
          { key: 'mobile_no' },
          { key: 'nid' },
          { key: 'district_name' },
          { key: 'upazilla_name' },
          { key: 'instrument_name' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.division_id': function (newVal, oldVal) {
        this.districtList = this.getDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
        this.upazilaList = this.getUpazilaList(newVal)
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    forward (item) {
      this.itemId = item.id
    },
    details (item) {
      this.item = item
      this.dtlsItemId = item.id
    },
    resetId () {
      this.itemId = 0
    },
    edit (item) {
      this.itemId = item.id
    },
    async loadData () {
      const params = Object.assign({}, this.search, { upazilla_id: this.authUser.office_detail.upazilla_id, page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(incentiveGrantServiceBaseUrl, BillPrepareList, params).then(response => {
        if (response.success) {
            this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
            this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const listData = data.map(item => {
        const farmerData = {
          farmer_name: item.application.farmer !== undefined ? item.application.farmer.name : '',
          farmer_name_bn: item.application.farmer !== undefined ? item.application.farmer.name_bn : '',
          mobile_no: item.application.farmer !== undefined ? item.application.farmer.mobile_no : '',
          nid: item.application.farmer !== undefined ? item.application.farmer.nid_no : ''
        }
        const supplierID = parseInt(item.application.supplier_id)
        const supplier = this.$store.state.incentiveGrant.commonObj.supplierList.find(item => item.value === supplierID)
        const circularList = this.$store.state.incentiveGrant.commonObj.circularList.find(fyr => fyr.value === item.circular_id)
        const landType = parseInt(item.application.land_type)
        const subsidyRateData = this.subsidyRateList.find(item => item.fiscal_year_id === circularList.fiscal_year_id && item.rate_type === landType)
        const landTypeList = this.landTypeList.find(landType => landType.value === item.application.land_type)
        const circularTypeList = this.$store.state.incentiveGrant.commonObj.subsidyCircularTypeList.find(fyr => fyr.value === circularList.type)
        const instrument = this.$store.state.incentiveGrant.commonObj.instrumentList.find(fyr => fyr.value === item.instrument_id)
        const divisionList = this.$store.state.commonObj.divisionList.find(divItem => divItem.value === item.application.farmer.address_info.division_id)
        const districtList = this.$store.state.commonObj.districtList.find(disItem => disItem.value === item.application.farmer.address_info.district_id)
        const upazillaList = this.$store.state.commonObj.upazilaList.find(upizila => upizila.value === item.application.farmer.address_info.upazilla_id)
        const supplierObj = { supplier_name: supplier !== undefined ? supplier.text_en : '', supplier_name_bn: supplier !== undefined ? supplier.text_bn : '' }
        const rateObj = { rate_per: subsidyRateData !== undefined ? subsidyRateData.percentage : '0' }
        const landTypeObj = { land_type_name: landTypeList !== undefined ? landTypeList.text : '' }
        const circularObj = { circular_name: circularList !== undefined ? circularList.text_en : '', circular_name_bn: circularList !== undefined ? circularList.text_bn : '' }
        const circularTypeObj = { circular_type_name: circularTypeList !== undefined ? circularTypeList.text_en : '', circular_type_name_bn: circularTypeList !== undefined ? circularTypeList.text_bn : '' }
        const instrumentObj = { instrument_name: instrument !== undefined ? instrument.text_en : '', instrument_name_bn: instrument !== undefined ? instrument.text_bn : '' }
        const upazillaObj = { upazilla_name: upazillaList !== undefined ? upazillaList.text_en : '', upazilla_name_bn: upazillaList !== undefined ? upazillaList.text_bn : '' }
        const divObj = { division_name: divisionList !== undefined ? divisionList.text_en : '', division_name_bn: divisionList !== undefined ? divisionList.text_bn : '' }
        const disObj = { district_name: districtList !== undefined ? districtList.text_en : '', district_name_bn: districtList !== undefined ? districtList.text_bn : '' }
        return Object.assign({}, item, instrumentObj, upazillaObj, farmerData, divObj, disObj, circularTypeObj, circularObj, landTypeObj, rateObj, supplierObj)
      })
      return listData
    },
    getDistrictList (divisionId = null) {
        const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

        if (divisionId) {
            return districtList.filter(district => district.division_id === divisionId)
        }

        return districtList
    },
    getUpazilaList (districtId = null) {
        const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

        if (districtId) {
            return upazilaList.filter(upazila => upazila.district_id === districtId)
        }

        return upazilaList
    }
  }
}
</script>
