<template>
  <b-container fluid>
    <b-row>
        <b-col lg="12" sm="12">
            <iq-card>
                <template v-slot:body>
                    <b-overlay :show="loading">
                        <b-row>
                            <b-col lg="12" sm="12">
                                <template>
                                    <div style="font-size:12px; background-color: var(--brand-color);">
                                        <h5 class="text-white text-center"> {{ $t('dae_subsidy.farmer_information') }}</h5>
                                    </div>
                                </template>
                                <b-table-simple striped bordered class="mt-2">
                                    <b-tr>
                                        <b-th>{{ $t('org_pro_division.division') }}</b-th>
                                        <b-td> {{ ($i18n.locale === 'bn') ? billInfo.division_name_bn : billInfo.division_name }} </b-td>
                                        <b-th>{{ $t('org_pro_district.district') }}</b-th>
                                        <b-td> {{ ($i18n.locale === 'bn') ? billInfo.district_name_bn : billInfo.district_name }} </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('org_pro_upazilla.upazilla') }}</b-th>
                                        <b-td> {{ ($i18n.locale === 'bn') ? billInfo.upazilla_name_bn : billInfo.upazilla_name }} </b-td>
                                        <b-th>{{ $t('dae_config.circular_type') }}</b-th>
                                        <b-td> {{ ($i18n.locale === 'bn') ? billInfo.circular_type_name_bn : billInfo.circular_type_name }} </b-td>
                                    </b-tr>
                                     <b-tr>
                                        <b-th>{{ $t('dae_subsidy.circular') }}</b-th>
                                        <b-td> {{ ($i18n.locale === 'bn') ? billInfo.circular_name_bn : billInfo.circular_name }} </b-td>
                                        <b-th>{{ $t('common_config.farmer_name') }}</b-th>
                                        <b-td> {{ ($i18n.locale === 'bn') ? billInfo.farmer_name_bn : billInfo.farmer_name }} </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('common_config.mobile_no') }}</b-th>
                                        <b-td> {{ ($i18n.locale === 'en' ? '0' : '০') }}{{ $n(billInfo.mobile_no, { useGrouping: false }) }} </b-td>
                                        <b-th>{{ $t('dae_grant_allocation_distribution.nid') }}</b-th>
                                        <b-td> {{ $n(billInfo.nid, { useGrouping: false }) }} </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('dae_subsidy.landType') }}</b-th>
                                        <b-td> {{ billInfo.land_type_name }} </b-td>
                                        <b-th>{{ $t('dae_config.subsidy_rate') }}</b-th>
                                        <b-td> {{ $n(billInfo.rate_per) }} </b-td>
                                    </b-tr>
                                </b-table-simple>
                                <template>
                                    <div style="font-size:12px; background-color: var(--brand-color);">
                                        <h5 class="text-white text-center"> {{ $t('dae_subsidy.instrument_info') }}</h5>
                                    </div>
                                </template>
                                <b-table-simple striped bordered class="mt-2">
                                    <b-tr>
                                        <b-th>{{ $t('dae_config.instrument_name') }}</b-th>
                                        <b-td> {{ ($i18n.locale === 'bn') ? billInfo.instrument_name_bn : billInfo.instrument_name }} </b-td>
                                        <b-th>{{ $t('dae_subsidy.brand_name') }}</b-th>
                                        <b-td> {{ ($i18n.locale === 'bn') ? billInfo.application.brand_name_bn : billInfo.application.brand_name }} </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('dae_subsidy.model') }}</b-th>
                                        <b-td> {{billInfo.application.model }} </b-td>
                                        <b-th>{{ $t('dae_subsidy.supplier_name') }}</b-th>
                                        <b-td> {{ ($i18n.locale === 'bn') ? billInfo.supplier_name_bn : billInfo.supplier_name }} </b-td>
                                    </b-tr>
                                </b-table-simple>
                                <template>
                                    <div style="font-size:12px; background-color: var(--brand-color);">
                                        <h5 class="text-white text-center"> {{ $t('dae_subsidy.bill_info') }}</h5>
                                    </div>
                                </template>
                                <b-table-simple striped bordered class="mt-2">
                                    <b-tr>
                                        <b-th>{{ $t('dae_subsidy.actual_price') }}</b-th>
                                        <b-td> {{ $n(billInfo.actual_price) }} </b-td>
                                        <b-th>{{ $t('dae_subsidy.subsidized_price') }}</b-th>
                                        <b-td> {{ $n(billInfo.subsidized_price) }} </b-td>
                                    </b-tr>
                                    <b-tr>
                                        <b-th>{{ $t('dae_subsidy.farmer_paid') }}</b-th>
                                        <b-td> {{ $n(billInfo.farmer_paid) }} </b-td>
                                        <b-th></b-th>
                                        <b-td></b-td>
                                    </b-tr>
                                </b-table-simple>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </template>
            </iq-card>
        </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
    props: ['item'],
    data () {
        return {
            loading: false,
            billInfo: '',
            search: {},
            users: []
        }
    },
    created () {
        this.billInfo = this.item
    },
    computed: {
    },
    methods: {
        async getListData () {
            return this.item
        }
    }
}
</script>
<style lang="scss">
    .hidden_header {
        display: none;
    }
</style>
